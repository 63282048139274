import React from "react";
import "./home.css";
import { Link } from "react-router-dom";

// img
import SectionImageLeft from "../../assets/images/85.jpg";
import SectionImageRight from "../../assets/images/318.jpeg";

function Home() {
  return (
    <div className="Home">
      <div className="section header">
        <div className="title">Découvrez un autre monde</div>
        <div className="text">Plongez dans un univers captivant.</div>
        <br />
        <div className="ctn-button">
          <Link className="button" to="/photos">
            Mes photos
          </Link>
        </div>
      </div>
      <div className="section-image left">
        <div className="ctn-content">
          <div className="sub-title">À propos</div>
          <div className="title">Qui suis-je ?</div>
          <div className="text">
            La photographie est l'un de mes passe-temps préférés. Je l'ai
            découvert grâce à ma femme qui m'a offert mon premier appareil
            photo. Il était entièrement mécanique et bien sûr argentique. Avec
            cet appareil d'un genre très simple, j'ai dû apprendre les bases de
            la photographie.
          </div>
        </div>
        <div className="ctn-image">
          <img src={SectionImageLeft} alt="image left section" />
        </div>
      </div>
      <div className="section-image right">
        <div className="ctn-image">
          <img src={SectionImageRight} alt="image right section" />
        </div>
        <div className="ctn-content">
          <div className="text">
            Puis je me suis converti au numérique. D'abord en prenant beaucoup
            de photos sans réfléchir.
            <br />
            <br /> Et petit à petit, en affinant les réglages, on découvre les
            nouvelles possibilités et on repousse les limites...
          </div>
        </div>
      </div>
      <div className="section footer">
        <div className="line"></div>
        <div className="text-block-2">
          Copyright ©{new Date().getFullYear()} DD PHOTOS. Tous les droits sont
          réservés. <br />
          Designed by{" "}
          <a href="https://aksoa.fr/" className="link">
            aksoa.fr
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
