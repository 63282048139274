import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyCrW6-kQ2iPeKbbaJkAWZz2PumyYe_KIYg",
  authDomain: "dd04photos.firebaseapp.com",
  projectId: "dd04photos",
  storageBucket: "dd04photos.appspot.com",
  messagingSenderId: "729580667757",
  appId: "1:729580667757:web:64d7a9f6b80e3e78405064",
  measurementId: "G-ZX99Z4ZH8B",
});
