import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// css
import "./App.css";
import "./assets/css/reset.css";
import "./assets/css/root.css";

// components
import Home from "./pages/home/index.jsx";
import Photos from "./pages/photos/index.jsx";
import BottomBar from "./components/bar/bottomBar";
import LoaderComponent from "./components/loader";
import Contact from "./pages/contact";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  const router = createBrowserRouter([
    {
      // errorElement: <ErrorPage />,
      path: "/",
      element: <BottomBar />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/photos",
          element: <Photos />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      {loading ? (
        // Affiche le loader pendant le chargement des données
        <LoaderComponent status={loading} />
      ) : (
        // Affiche le contenu de l'application une fois les données chargées
        <RouterProvider router={router} />
      )}
    </div>
  );
}

export default App;
