import React, { useState } from "react";
import "./bottomBar.css";

// icons
import { RiHome5Line, RiHome5Fill } from "react-icons/ri";
import { AiOutlineMessage, AiFillMessage } from "react-icons/ai";
import { FaRegImages, FaImages } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function BottomBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(location.pathname);
  return (
    <>
      <div className="BottomBar">
        {data === "/" ? (
          <div className="container active">
            <RiHome5Fill className="icon" /> <div className="text">HOME</div>
          </div>
        ) : (
          <div
            className="container"
            onClick={() => [navigate("/"), setData("/")]}
          >
            <RiHome5Line className="icon" />
          </div>
        )}
        {data === "/photos" ? (
          <div className="container active">
            <FaImages className="icon" /> <div className="text">PHOTOS</div>
          </div>
        ) : (
          <div
            className="container"
            onClick={() => [navigate("/photos"), setData("/photos")]}
          >
            <FaRegImages className="icon" />
          </div>
        )}
        {data === "/contact" ? (
          <div className="container active">
            <AiFillMessage className="icon" />{" "}
            <div className="text">CONTACT</div>
          </div>
        ) : (
          <div
            className="container"
            onClick={() => [navigate("/contact"), setData("/contact")]}
          >
            <AiOutlineMessage className="icon" />
          </div>
        )}
      </div>
      <Outlet />
    </>
  );
}

export default BottomBar;
