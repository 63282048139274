import React from "react";
import "./contact.css";

// icons
import { TbMailFilled } from "react-icons/tb";
import { FaFacebookF } from "react-icons/fa";

function Contact() {
  return (
    <div className="Contact">
      <div className="header">
        <div className="title text-6xl">Contact.</div>
      </div>
      <div className="wrapper">
        <div className="ctn-intro">Besoin de me contacter ? </div>
        <div className="ctn-elfb">
          <div className="flex">
            <div className="ctn">
              <div className="ctn-icon">
                <TbMailFilled className="icon" />
              </div>
              <div className="left">
                <span>EMAIL</span>
                <div className="text">contact@dd04photos.fr</div>
              </div>
            </div>
            <div className="ctn">
              <div className="ctn-icon fb">
                <FaFacebookF className="icon" />
              </div>
              <div className="left">
                <span>FACEBOOK</span>
                <div className="text">@dd04photos</div>
              </div>
            </div>
          </div>
        </div>
        <div className="ctn-open">
          <a href="mailto:contact@dd04photos.fr">
            <img
              src="https://cdn-icons-png.flaticon.com/512/948/948695.png"
              alt=""
            />
            <div className="text">Ouvrir ma boîte aux lettres</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
