import React from "react";
import "./loaderComponent.css";

function LoaderComponent() {
  return (
    <div className="LoaderComponent">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LoaderComponent;
